import { initializeApp } from "firebase/app";
import { getDatabase, ref, push, onValue, serverTimestamp, set, get, orderByChild } from "firebase/database";
import { query } from "firebase/firestore";

import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyC0rnIRFFLXoqdv84HWuLZzMJuxvpSJRM4",
  authDomain: "astro-booster.firebaseapp.com",
  databaseURL: "https://astro-booster-default-rtdb.firebaseio.com",
  projectId: "astro-booster",
  storageBucket: "astro-booster.appspot.com",
  messagingSenderId: "494915822458",
  appId: "1:494915822458:web:a43ef914dcae9f233bacc3",
  measurementId: "G-PD2VN2QSD1"
};

//! Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

//! Initialize Firebase Cloud Messaging and get a reference to the service
const messaging = getMessaging(app);

export { database, ref, push, onValue, serverTimestamp, set, messaging, get, orderByChild, query };