// Dashboard
export const SET_IS_LOADING = "SET_IS_LOADING";
export const SET_IS_SIDEBAR_OPEN = "SET_IS_SIDEBAR_OPEN";

//! Common
export const OPEN_TEXT_MODAL = 'OPEN_TEXT_MODAL';
export const CLOSE_TEXT_MODAL = 'CLOSE_TEXT_MODAL';

//! Astrologer
export const GET_ASTROLOGER = 'GET_ASTROLOGER';
export const SET_ASTROLOGER = 'SET_ASTROLOGER';
export const GET_ENQUIRY_ASTROLOGER = 'GET_ENQUIRY_ASTROLOGER';
export const SET_ENQUIRY_ASTROLOGER = 'SET_ENQUIRY_ASTROLOGER';
export const GET_ASTROLOGER_BY_ID = 'GET_ASTROLOGER_BY_ID';
export const SET_ASTROLOGER_BY_ID = 'SET_ASTROLOGER_BY_ID';
export const GET_ASTROLOGER_DURATION_BY_ID = 'GET_ASTROLOGER_DURATION_BY_ID';
export const SET_ASTROLOGER_DURATION_BY_ID = 'SET_ASTROLOGER_DURATION_BY_ID';
export const CREATE_ASTROLOGER = 'CREATE_ASTROLOGER';
export const UPDATE_ASTROLOGER_BY_ID = 'UPDATE_ASTROLOGER_by_ID';
export const DELETE_ASTROLOGER_BY_ID = 'DELETE_ASTROLOGER_by_ID';
export const GET_CHAT_HISTORY_BY_ASTROLOGER_ID = 'GET_CHAT_HISTORY_BY_ASTROLOGER_ID';
export const SET_CHAT_HISTORY_BY_ASTROLOGER_ID = 'SET_CHAT_HISTORY_BY_ASTROLOGER_ID';
export const GET_CALL_HISTORY_BY_ASTROLOGER_ID = 'GET_CALL_HISTORY_BY_ASTROLOGER_ID';
export const SET_CALL_HISTORY_BY_ASTROLOGER_ID = 'SET_CALL_HISTORY_BY_ASTROLOGER_ID';
export const GET_VIDEO_CALL_HISTORY_BY_ASTROLOGER_ID = 'GET_VIDEO_CALL_HISTORY_BY_ASTROLOGER_ID';
export const SET_VIDEO_CALL_HISTORY_BY_ASTROLOGER_ID = 'SET_VIDEO_CALL_HISTORY_BY_ASTROLOGER_ID';
export const GET_LIVE_HISTORY_BY_ASTROLOGER_ID = 'GET_LIVE_HISTORY_BY_ASTROLOGER_ID';
export const SET_LIVE_HISTORY_BY_ASTROLOGER_ID = 'SET_LIVE_HISTORY_BY_ASTROLOGER_ID';
export const GET_GIFT_HISTORY_BY_ASTROLOGER_ID = 'GET_GIFT_HISTORY_BY_ASTROLOGER_ID';
export const SET_GIFT_HISTORY_BY_ASTROLOGER_ID = 'SET_GIFT_HISTORY_BY_ASTROLOGER_ID';
export const GET_REVIEW_BY_ASTROLOGER_ID = 'GET_REVIEW_BY_ASTROLOGER_ID';
export const SET_REVIEW_BY_ASTROLOGER_ID = 'SET_REVIEW_BY_ASTROLOGER_ID';
export const GET_TRANSACTION_HISTORY_BY_ASTROLOGER_ID = 'GET_TRANSACTION_HISTORY_BY_ASTROLOGER_ID';
export const SET_TRANSACTION_HISTORY_BY_ASTROLOGER_ID = 'SET_TRANSACTION_HISTORY_BY_ASTROLOGER_ID';
export const GET_PUJA_HISTORY_BY_ASTROLOGER_ID = 'GET_PUJA_HISTORY_BY_ASTROLOGER_ID';
export const SET_PUJA_HISTORY_BY_ASTROLOGER_ID = 'SET_PUJA_HISTORY_BY_ASTROLOGER_ID';
export const UPDATE_WALLET_BY_ASTROLOGER_ID = 'UPDATE_WALLET_BY_ASTROLOGER_ID';
export const VERIFY_ASTROLOGER_PROFILE = 'VERIFY_ASTROLOGER_PROFILE';
export const CHANGE_ASTROLOGER_CHAT_STATUS = 'CHANGE_ASTROLOGER_CHAT_STATUS';
export const CHANGE_ASTROLOGER_CALL_STATUS = 'CHANGE_ASTROLOGER_CALL_STATUS';
export const CHANGE_ASTROLOGER_VIDEO_CALL_STATUS = 'CHANGE_ASTROLOGER_VIDEO_CALL_STATUS';
export const GET_ASTROLOGER_WITHDRAWAL_REQUEST = 'GET_ASTROLOGER_WITHDRAWAL_REQUEST';
export const SET_ASTROLOGER_WITHDRAWAL_REQUEST = 'SET_ASTROLOGER_WITHDRAWAL_REQUEST';
export const APPROVE_ASTROLOGER_WITHDRAWAL_REQUEST_AMOUNT = 'APPROVE_ASTROLOGER_WITHDRAWAL_REQUEST_AMOUNT';

//! Customer
export const GET_CUSTOMER = 'GET_CUSTOMER';
export const SET_CUSTOMER = 'SET_CUSTOMER';
export const GET_CUSTOMER_BY_ID = 'GET_CUSTOMER_BY_ID';
export const SET_CUSTOMER_BY_ID = 'SET_CUSTOMER_BY_ID';
export const CREATE_CUSTOMER = 'CREATE_CUSTOMER';
export const UPDATE_CUSTOMER_BY_ID = 'UPDATE_CUSTOMER_by_ID';
export const DELETE_CUSTOMER_BY_ID = 'DELETE_CUSTOMER_by_ID';
export const CHANGE_CUSTOMER_BANNED_UNBANNED_STATUS = 'CHANGE_CUSTOMER_BANNED_UNBANNED_STATUS';
export const UPDATE_WALLET_BY_CUSTOMER_ID = 'UPDATE_WALLET_BY_CUSTOMER_ID';
export const GET_CHAT_HISTORY_BY_CUSTOMER_ID = 'GET_CHAT_HISTORY_BY_CUSTOMER_ID';
export const SET_CHAT_HISTORY_BY_CUSTOMER_ID = 'SET_CHAT_HISTORY_BY_CUSTOMER_ID';
export const GET_CALL_HISTORY_BY_CUSTOMER_ID = 'GET_CALL_HISTORY_BY_CUSTOMER_ID';
export const SET_CALL_HISTORY_BY_CUSTOMER_ID = 'SET_CALL_HISTORY_BY_CUSTOMER_ID';
export const GET_VIDEO_CALL_HISTORY_BY_CUSTOMER_ID = 'GET_VIDEO_CALL_HISTORY_BY_CUSTOMER_ID';
export const SET_VIDEO_CALL_HISTORY_BY_CUSTOMER_ID = 'SET_VIDEO_CALL_HISTORY_BY_CUSTOMER_ID';
export const GET_LIVE_HISTORY_BY_CUSTOMER_ID = 'GET_LIVE_HISTORY_BY_CUSTOMER_ID';
export const SET_LIVE_HISTORY_BY_CUSTOMER_ID = 'SET_LIVE_HISTORY_BY_CUSTOMER_ID';
export const GET_PUJA_HISTORY_BY_CUSTOMER_ID = 'GET_PUJA_HISTORY_BY_CUSTOMER_ID';
export const SET_PUJA_HISTORY_BY_CUSTOMER_ID = 'SET_PUJA_HISTORY_BY_CUSTOMER_ID';
export const GET_ORDER_HISTORY_BY_CUSTOMER_ID = 'GET_ORDER_HISTORY_BY_CUSTOMER_ID';
export const SET_ORDER_HISTORY_BY_CUSTOMER_ID = 'SET_ORDER_HISTORY_BY_CUSTOMER_ID';
export const GET_FOLLOWING_HISTORY_BY_CUSTOMER_ID = 'GET_FOLLOWING_HISTORY_BY_CUSTOMER_ID';
export const SET_FOLLOWING_HISTORY_BY_CUSTOMER_ID = 'SET_FOLLOWING_HISTORY_BY_CUSTOMER_ID';
export const GET_REVIEW_HISTORY_BY_CUSTOMER_ID = 'GET_REVIEW_HISTORY_BY_CUSTOMER_ID';
export const SET_REVIEW_HISTORY_BY_CUSTOMER_ID = 'SET_REVIEW_HISTORY_BY_CUSTOMER_ID';

//! Astro-Puja
export const GET_PUJA = 'GET_PUJA';
export const SET_PUJA = 'SET_PUJA';
export const CREATE_PUJA = 'CREATE_PUJA';
export const UPDATE_PUJA = 'UPDATE_PUJA';
export const DELETE_PUJA = 'DELETE_PUJA';
export const GET_PUJA_REQUEST = 'GET_PUJA_REQUEST';
export const SET_PUJA_REQUEST = 'SET_PUJA_REQUEST';
export const ASSIGN_PUJA = 'ASSIGN_PUJA';
export const CHANGE_PUJA_STATUS = 'CHANGE_PUJA_STATUS';
export const GET_PUJA_BOOKED = 'GET_PUJA_BOOKED';
export const SET_PUJA_BOOKED = 'SET_PUJA_BOOKED';
export const GET_PUJA_HISTORY = 'GET_PUJA_HISTORY';
export const SET_PUJA_HISTORY = 'SET_PUJA_HISTORY';

//! History
export const GET_CHAT_HISTORY = "GET_CHAT_HISTORY";
export const SET_CHAT_HISTORY = "SET_CHAT_HISTORY";
export const GET_CALL_HISTORY = "GET_CALL_HISTORY";
export const SET_CALL_HISTORY = "SET_CALL_HISTORY";
export const GET_VIDEO_CALL_HISTORY = "GET_VIDEO_CALL_HISTORY";
export const SET_VIDEO_CALL_HISTORY = "SET_VIDEO_CALL_HISTORY";
export const GET_LIVE_HISTORY = "GET_LIVE_HISTORY";
export const SET_LIVE_HISTORY = "SET_LIVE_HISTORY";
export const GET_GIFT_HISTORY = "GET_GIFT_HISTORY";
export const SET_GIFT_HISTORY = "SET_GIFT_HISTORY";

//! Skill
export const GET_SKILL = 'GET_SKILL';
export const SET_SKILL = 'SET_SKILL';
export const CREATE_SKILL = 'CREATE_SKILL';
export const UPDATE_SKILL = 'UPDATE_SKILL';
export const DELETE_SKILL = 'DELETE_SKILL';

//! Remedies
export const GET_REMEDIES = 'GET_REMEDIES';
export const SET_REMEDIES = 'SET_REMEDIES';
export const CREATE_REMEDIES = 'CREATE_REMEDIES';
export const UPDATE_REMEDIES = 'UPDATE_REMEDIES';
export const DELETE_REMEDIES = 'DELETE_REMEDIES';

//! Expertise
export const GET_EXPERTISE = "GET_EXPERTISE";
export const SET_EXPERTISE = "SET_EXPERTISE";
export const CREATE_EXPERTISE = "CREATE_EXPERTISE";
export const UPDATE_EXPERTISE = "UPDATE_EXPERTISE";
export const DELETE_EXPERTISE = "DELETE_EXPERTISE";
export const GET_MAIN_EXPERTISE = "GET_MAIN_EXPERTISE";
export const SET_MAIN_EXPERTISE = "SET_MAIN_EXPERTISE";
export const CREATE_MAIN_EXPERTISE = "CREATE_MAIN_EXPERTISE";
export const UPDATE_MAIN_EXPERTISE = "UPDATE_MAIN_EXPERTISE";
export const DELETE_MAIN_EXPERTISE = "DELETE_MAIN_EXPERTISE";

//! Static Page
export const GET_TERMS_AND_CONDITION = 'GET_TERMS_AND_CONDITION';
export const SET_TERMS_AND_CONDITION = 'SET_TERMS_AND_CONDITION';
export const CREATE_TERMS_AND_CONDITION = 'CREATE_TERMS_AND_CONDITION';
export const GET_PRIVACY_POLICY = 'GET_PRIVACY_POLICY';
export const SET_PRIVACY_POLICY = 'SET_PRIVACY_POLICY';
export const CREATE_PRIVACY_POLICY = 'CREATE_PRIVACY_POLICY';
export const GET_ABOUT_US = 'GET_ABOUT_US';
export const SET_ABOUT_US = 'SET_ABOUT_US';
export const CREATE_ABOUT_US = 'CREATE_ABOUT_US';

//TODO---------------------Working-----------------------TODO// 

//! Banner
export const UPLOAD_APP_BANNER = "UPLOAD_APP_BANNER";
export const UPLOAD_WEB_BANNER = "UPLOAD_WEB_BANNER";
export const GET_APP_BANNERS = "GET_APP_BANNERS";
export const SET_APP_BANNERS = "SET_APP_BANNERS";
export const DELETE_BANNERS = "DELETE_BANNERS";
export const EDIT_BANNERS = "EDIT_BANNERS";
export const CHANGE_BANNER_STATUS = "CHANGE_BANNER_STATUS";

//! Astro-Mall
export const GET_ASTRO_MALL_CATEGORY = 'GET_ASTRO_MALL_CATEGORY';
export const SET_ASTRO_MALL_CATEGORY = 'SET_ASTRO_MALL_CATEGORY';
export const CREATE_ASTRO_MALL_CATEGORY = 'CREATE_ASTRO_MALL_CATEGORY';
export const UPDATE_ASTRO_MALL_CATEGORY = 'UPDATE_ASTRO_MALL_CATEGORY';
export const DELETE_ASTRO_MALL_CATEGORY = 'DELETE_ASTRO_MALL_CATEGORY';

export const GET_ASTRO_MALL_PRODUCT = 'GET_ASTRO_MALL_PRODUCT';
export const SET_ASTRO_MALL_PRODUCT = 'SET_ASTRO_MALL_PRODUCT';
export const CREATE_ASTRO_MALL_PRODUCT = 'CREATE_ASTRO_MALL_PRODUCT';
export const UPDATE_ASTRO_MALL_PRODUCT = 'UPDATE_ASTRO_MALL_PRODUCT';
export const DELETE_ASTRO_MALL_PRODUCT = 'DELETE_ASTRO_MALL_PRODUCT';

//! All_Products
export const GET_ALL_PRODUCTS = 'GET_ALL_PRODUCTS';
export const SET_ALL_PRODUCTS = 'SET_ALL_PRODUCTS';

//! All_HISTORY
export const GET_ORDER_HISTORY = 'GET_ORDER_HISTORY';
export const SET_ORDER_HISTORY = 'SET_ORDER_HISTORY';
export const CHANGE_ORDER_STATUS = 'CHANGE_ORDER_STATUS';

//! All_HISTORY
export const GET_ASTROLOGER_INQUIRY = 'GET_ASTROLOGER_INQUIRY';
export const SET_ASTROLOGER_INQUIRY = 'SET_ASTROLOGER_INQUIRY';


//! Astro-Puja
export const GET_ASTRO_PUJA_PUJA = 'GET_ASTRO_PUJA_PUJA';
export const SET_ASTRO_PUJA_PUJA = 'SET_ASTRO_PUJA_PUJA';
export const CREATE_ASTRO_PUJA_PUJA = 'CREATE_ASTRO_PUJA_PUJA';
export const UPDATE_ASTRO_PUJA_PUJA = 'UPDATE_ASTRO_PUJA_PUJA';
export const DELETE_ASTRO_PUJA_PUJA = 'DELETE_ASTRO_PUJA_PUJA';

// Astro-Puja Request
export const GET_ASTRO_PUJA_REQUEST = 'GET_ASTRO_PUJA_REQUEST';
export const SET_ASTRO_PUJA_REQUEST = 'SET_ASTRO_PUJA_REQUEST';
export const UPDATE_ASTRO_PUJA_REQUEST = 'UPDATE_ASTRO_PUJA_REQUEST';

// Astro-Puja-Accepted
export const GET_ASTRO_PUJA_ACCEPTED = 'GET_ASTRO_PUJA_ACCEPTED';
export const SET_ASTRO_PUJA_ACCEPTED = 'SET_ASTRO_PUJA_ACCEPTED';

// Astro-Puja-Rejected
export const GET_ASTRO_PUJA_REJECTED = 'GET_ASTRO_PUJA_REJECTED';
export const SET_ASTRO_PUJA_REJECTED = 'SET_ASTRO_PUJA_REJECTED';

// Astro-Puja-Booked
export const GET_ASTRO_PUJA_BOOKED = 'GET_ASTRO_PUJA_BOOKED';
export const SET_ASTRO_PUJA_BOOKED = 'SET_ASTRO_PUJA_BOOKED';

//! Astro-Blog
export const ADD_ASTRO_BLOG = 'ADD_ASTRO_BLOG';
export const GET_ASTRO_BLOG = 'GET_ASTRO_BLOG';
export const SET_ASTRO_BLOG = 'SET_ASTRO_BLOG';
export const DELETE_ASTRO_BLOG = 'DELETE_ASTRO_BLOG';
export const UPDATE_ASTRO_BLOG = 'UPDATE_ASTRO_BLOG';

//Review
export const ADD_ASTROLOGERS_REVIEWS = "ADD_ASTROLOGERS_REVIEWS";
export const GET_ASTROLOGERS_REVIEWS = "GET_ASTROLOGERS_REVIEWS";
export const SET_ASTROLOGERS_REVIEWS = "SET_ASTROLOGERS_REVIEWS";
export const GET_APP_REVIEWS = "GET_APP_REVIEWS";
export const SET_APP_REVIEWS = "SET_APP_REVIEWS";
export const UPDATE_ASTROLOER_REVIEW_STATUS = "UPDATE_ASTROLOER_REVIEW_STATUS";
export const DELETE_ASTROLOGER_REVIEW = "DELETE_ASTROLOGER_REVIEW";
export const UPDATE_ASTROLOGER_REVIEW = "UPDATE_ASTROLOGER_REVIEW";

//Customer
// export const CREATE_CUSTOMER = "CREATE_CUSTOMER";
export const GET_ALL_CUSTOMER = "GET_ALL_CUSTOMER";
export const SET_ALL_CUSTOMER = "SET_ALL_CUSTOMER";
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const BAN_CUSTOMER = "BAN_CUSTOMER";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";

//Astrologer
export const GET_ALL_ASTROLOGER = "GET_ALL_ASTROLOGER";
export const SET_ALL_ASTROLOGER = "SET_ALL_ASTROLOGER";
export const UPDATE_ASTROLOGER_CHAT_STATUS = "UPDATE_ASTROLOGER_CHAT_STATUS";
export const UPDATE_ASTROLOER_CALL_STATUS = "UPDATE_ASTROLOER_CALL_STATUS";
export const GET_ENQUIRY_ASTROLOGERS = "GET_ENQUIRY_ASTROLOGERS";
export const SET_ENQUIRY_ASTROLOGERS = "SET_ENQUIRY_ASTROLOGERS";
export const UPDATE_ENQUIRY_STATUS = "UPDATE_ENQUIRY_STATUS";
export const UPDATE_ASTROLOGER_DATA = "UPDATE_ASTROLOGER_DATA";
export const ADD_ASTROLOGER = "ADD_ASTROLOGER";
export const VERIFY_UNVERIFY_ASTROLOGER = "VERIFY_UNVERIFY_ASTROLOGER";
export const DELETE_ASTROLOGER = "DELETE_ASTROLOGER";
export const GET_RECENT_LIVE_STREAMING = "GET_RECENT_LIVE_STREAMING";
export const SET_RECENT_LIVE_STREAMING = "SET_RECENT_LIVE_STREAMING";


export const CREATE_QUALIFICATION = "CREATE_QUALIFICATION";
export const GET_QUALIFICATION = "GET_QUALIFICATION";
export const SET_QUALIFICATION = "SET_QUALIFICATION";
export const UPDATE_QUALIFICATION = "UPDATE_QUALIFICATION";

export const GET_REQUEST_ASTROLOGER = "GET_REQUEST_ASTROLOGER";
export const SET_REQUEST_ASTROLOGER = "SET_REQUEST_ASTROLOGER";
export const UPDATE_REQUEST_ASTROLOGER = "UPDATE_REQUEST_ASTROLOGER";




// Gifts
export const CREATE_GIFT = "CREATE_GIFT";
export const GET_ALL_GIFT = "GET_ALL_GIFT";
export const SET_ALL_GIFT = "SET_ALL_GIFT";
export const UPDATE_GIFT = "UPDATE_GIFT";
export const DELETE_GIFT = "DELETE_GIFT";



//Notification
export const GET_CUSTOMER_NOTIFICATIONS = "GET_CUSTOMER_NOTIFICATIONS";
export const GET_ASTROLOGER_NOTIFICATIONS = "GET_ASTROLOGER_NOTIFICATIONS";
export const SET_CUSTOMER_NOTIFICATIONS = "GET_CUSTOMER_NOTIFICATIONS";
export const SET_ASTROLOGER_NOTIFICATIONS = "GET_ASTROLOGER_NOTIFICATIONS";
export const SEND_CUSTOMER_NOTIFICATIONS = "SEND_CUSTOMER_NOTIFICATIONS";
export const SEND_ASTROLOGER_NOTIFICATIONS = "SEND_ASTROLOGER_NOTIFICATIONS";
export const DELETE_CUSTOMER_NOTIFICATIONS = "GET_CUSTOMER_NOTIFICATIONS";
export const DELETE_ASTROLOGER_NOTIFICATIONS = "GET_ASTROLOGER_NOTIFICATIONS";



//Dashboard
export const GET_DASHBOARD = "GET_DASHBOARD";
export const SET_DASHBOARD = "SET_DASHBOARD";

export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA";
export const SET_DASHBOARD_DATA = "SET_DASHBOARD_DATA";
export const GET_EARNING_CHART = 'GET_EARNING_CHART';
export const SET_EARNING_CHART = 'SET_EARNING_CHART';
export const GET_EARNING_GRAPH = 'GET_EARNING_GRAPH';
export const SET_EARNING_GRAPH = 'SET_EARNING_GRAPH';

//Reports
export const GET_ADMIN_EARNINGS = "GET_ADMIN_EARNINGS";
export const SET_ADMIN_EARNINGS = "SET_ADMIN_EARNINGS";
export const GET_RECIEPT_SUMMARY = 'GET_RECIEPT_SUMMARY'
export const SET_RECIEPT_SUMMARY = 'SET_RECIEPT_SUMMARY'

//Language
export const CREATE_LANGUAGE = "CREATE_LANGUAGE";
export const GET_ALL_LANGUAGE = "GET_ALL_LANGUAGE";
export const SET_ALL_LANGUAGE = "SET_ALL_LANGUAGE";
export const UPDATE_LANGUAGE = "UPDATE_LANGUAGE";
export const DELETE_LANGUAGE = "DELETE_LANGUAGE";

//Country
export const GET_ALL_COUNTRY = "GET_ALL_COUNTRY";
export const SET_ALL_COUNTRY = "SET_ALL_COUNTRY";

//Recharge Plan

export const CREATE_RECHARGE_PLAN = 'CREATE_RECHARGE_PLAN';
export const GET_RECHARGE_PLAN = 'GET_RECHARGE_PLAN'
export const SET_RECHARGE_PLAN = 'SET_RECHARGE_PLAN'
export const UPDATE_RECHARGE_PLAN = 'UPDATE_RECHARGE_PLAN'
export const DELETE_RECHARGE_PLAN = 'DELETE_RECHARGE_PLAN'
export const UPDATE_RECHARGE_PLAN_STATUS = 'UPDATE_RECHARGE_PLAN_STATUS'

//FirstRecharge Offer
export const CREATE_FIRST_RECHARGE_OFFER = 'CREATE_FIRST_RECHARGE_OFFER'
export const GET_FIRST_RECHARGE_OFFER = 'GET_FIRST_RECHARGE_OFFER'
export const SET_FIRST_RECHARGE_OFFER = 'SET_FIRST_RECHARGE_OFFER'
export const UPDATE_FIRST_RECHARGE_OFFER = 'UPDATE_FIRST_RECHARGE_OFFER'
export const UPDATE_FIRST_RECHARGE_OFFER_STATUS = 'UPDATE_FIRST_RECHARGE_OFFER_STATUS'
export const DELETE_FIRST_RECHARGE_OFFER = 'DELETE_FIRST_RECHARGE_OFFER'

// Pages
export const ADD_ASK_QUESTIONS = "ADD_ASK_QUESTIONS"
export const GET_ASK_QUESTION = "GET_ASK_QUESTION"
export const SET_ASK_QUESTION = "SET_ASK_QUESTION"
export const DELETE_ASK_QUESTION = "DELETE_ASK_QUESTION"
export const UPDATE_ASK_QUESTION = "UPDATE_ASK_QUESTION"

export const ADD_RELIGION_SPRITUALITY = "ADD_RELIGION_SPRITUALITY"
export const GET_ALL_RELIGION_SPRITUALITY = "GET_ALL_RELIGION_SPRITUALITY"
export const SET_ALL_RELIGION_SPRITUALITY = "SET_ALL_RELIGION_SPRITUALITY"
export const DELETE_RELIGION_SPRITUALITY = "DELETE_RELIGION_SPRITUALITY"
export const UPDATE_RELIGION_SPRITUALITY = "UPDATE-RELIGION-SPRITUALITY"

export const ADD_ASTRO_MAGAZINE = "ADD_ASTRO_MAGAZINE"
export const GET_ALL_ASTRO_MAGAZINE = "GET_ALL_ASTRO_MAGAZINE"
export const SET_ALL_ASTRO_MAGAZINE = "SET_ALL_ASTRO_MAGAZINE"
export const DELETE_ASTRO_MAGAZINE = "DELETE_ASTRO_MAGAZINE"
export const UPDATE_ASTRO_MAGAZINE = "UPDATE_ASTRO_MAGAZINE"

export const ADD_ANNOUNCEMENT = "ADD_ANNOUNCEMENT"
export const GET_ALL_ANNOUNCEMENT = "GET_ALL_ANNOUNCEMENT"
export const SET_ALL_ANNOUNCEMENT = "SET_ALL_ANNOUNCEMENT"
export const DELETE_ANNOUNCEMENT = "DELETE_ANNOUNCEMENT"
export const UPDATE_ANNOUNCEMENT = "UPDATE_ANNOUNCEMENT"

export const ADD_BIRHAT_HOROSCOPE = "ADD_BIRHAT_HOROSCOPE"
export const GET_ALL_BIRHAT_HOROSCOPE = "GET_ALL_BIRHAT_HOROSCOPE"
export const SET_ALL_BIRHAT_HOROSCOPE = "SET_ALL_BIRHAT_HOROSCOPE"
export const DELETE_BIRHAT_HOROSCOPE = "DELETE_BIRHAT_HOROSCOPE"
export const UPDATE_BIRHAT_HOROSCOPE = "UPDATE_BIRHAT_HOROSCOPE"

export const ADD_AUSPICIOUS_TIME = "ADD_AUSPICIOUS_TIME"
export const GET_ALL_AUSPICIOUS_TIME = "GET_ALL_AUSPICIOUS_TIME"
export const SET_ALL_AUSPICIOUS_TIME = "SET_ALL_AUSPICIOUS_TIME"
export const DELETE_ALL_AUSPICIOUS_TIME = "DELETE_ALL_AUSPICIOUS_TIME"
export const UPDATE_AUSPICIOUS_TIME = "UPDATE_AUSPICIOUS_TIME"

export const ADD_DAILY_PANCHANG = "ADD_DAILY_PANCHANG"
export const GET_ALL_DAILY_PANCHANG = "GET_ALL_DAILY_PANCHANG"
export const SET_ALL_DAILY_PANCHANG = "SET_ALL_DAILY_PANCHANG"
export const DELETE_DAILY_PANCHANG = "DELETE_DAILY_PANCHANG"
export const UPDATE_DAILY_PANCHANG = "UPDATE_DAILY_PANCHANG"

export const ADD_YELLOW_BOOK = "ADD_YELLOW_BOOK"
export const GET_ALL_YELLOW_BOOK = "GET_ALL_YELLOW_BOOK"
export const SET_ALL_YELLOW_BOOK = "SET_ALL_YELLOW_BOOK"
export const DELETE_YELLOW_BOOK = "DELETE_YELLOW_BOOK"
export const UPDATE_YELLOW_BOOK = "UPDATE_YELLOW_BOOK"



export const CREATE_APP_TUTORIALS = "CREATE_APP_TUTORIALS"
export const GET_APP_TUTORIALS = "GET_APP_TUTORIALS"
export const SET_APP_TUTORIALS = "SET_APP_TUTORIALS"
export const DELETE_APP_TUTORIALS = "DELETE_APP_TUTORIALS"



// App Astrokunj

export const ADD_NUMEROLOGY = "ADD_NUMEROLOGY"
export const SET_NUMEROLOGY = "SET_NUMEROLOGY"
export const GET_NUMEROLOGY = "GET_NUMEROLOGY"
export const DELETE_NUMEROLOGY = "DELETE_NUMEROLOGY"
export const UPDATE_NUMEROLOGY = "UPDATE_NUMEROLOGY"

export const ADD_VIVAHMUHURAT = "ADD_VIVAHMUHURAT"
export const SET_VIVAHMUHURAT = "SET_VIVAHMUHURAT"
export const GET_VIVAHMUHURAT = "GET_VIVAHMUHURAT"
export const DELETE_VIVAHMUHURAT = "DELETE_VIVAHMUHURAT"
export const UPDATE_VIVAHMUHURAT = "UPDATE_VIVAHMUHURAT"


export const ADD_MUNDANMUHURAT = "ADD_MUNDANMUHURAT"
export const SET_MUNDANMUHURAT = "SET_MUNDANMUHURAT"
export const GET_MUNDANMUHURAT = "GET_MUNDANMUHURAT"
export const DELETE_MUNDANMUHURAT = "DELETE_MUNDANMUHURAT"
export const UPDATE_MUNDANMUHURAT = "UPDATE_MUNDANMUHURAT"

export const ADD_ANNAPRASHAN = "ADD_ANNAPRASHAN"
export const SET_ANNAPRASHAN = "SET_ANNAPRASHAN"
export const GET_ANNAPRASHAN = "GET_ANNAPRASHAN"
export const DELETE_ANNAPRASHAN = "DELETE_ANNAPRASHAN"
export const UPDATE_ANNAPRASHAN = "UPDATE_ANNAPRASHAN"


